import { Container, Row } from 'react-bootstrap';

const About = () => {
    return (
        <div className="justify-content-center" id="about">
            <Container id="geospringer-about-wrapper">
                <Row className="justify-content-center" id="about-title">
                    <h1><b>impact</b> statement</h1>
                </Row>
                <Row className="justify-content-center" id="about-content">
                    <Row xs={6} md={4}>
                        <div  id="about-text">
                            <p id="about-text-inner">Here at Metaphysics Arthaus we believe in a carbon neutral future.
                            Thanks to our friends at <a style={{textAlign: "left", padding: 0, lineHeight: "22px", float: "none", marginRight: 0}} href="https://forestcarbonoffsets.net/"><u>Forest Carbon Offsets</u></a>, we are 
                            able to bring our reality-bending projects to life whilst offsetting the carbon generated by our blockchain 
                            activity. For all our projects, we calculate the at-mint carbon cost per transaction and offset this 10-fold.
                            </p>
                        </div>
                    </Row>
                </Row>
            </Container>
        </div>
    );
};

export default About;