import { Container, Row } from 'react-bootstrap';
import Timeline from '../util/Timeline';

const Roadmap = () => {
    return ( 
        <div className="home-page">
            <Container style={{padding:0}}>
                <Row>
                    <div id="about-roadmap"  style={{marginTop: 90, padding:0}}>
                        <Row className="justify-content-center">
                            <h1 style={{zIndex: 2}}><b>road</b>map</h1>
                        </Row>
                        <br></br>
                        <div id="about-roadmap--fade"></div>
                        <Timeline></Timeline>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default Roadmap;
