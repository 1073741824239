import React from 'react';
import { Container, Row } from 'react-bootstrap';

const Tile = (props) => {

    return (
        <div 
         className="justify-content-start"
         style={{
             backgroundColor: '#1f1f1f', 
             borderRadius: 30, width: "100%", 
             maxWidth: 500, minWidth: 300,
             margin: "0 auto",
             textAlign: "center",
             boxShadow: "0 0 10px #2f2f2f"
        }}>
            <div 
                className="justify-content-center" 
                id="tile-wrapper"
                style={{
                    height: 400, width:"100%", 
                    maxWidth: 500,
                    backgroundColor:'#ff8a1d',
                    backgroundImage: `url(${props.image})`,
                    backgroundSize: "540px 405px",
                    backgroundPosition: "center",
                    display: 'inline-flex',
                    borderRadius: 30}}>
            </div>
            <br></br>
            <Container>
                <Row>
                    <a href={props.href} style={{margin: "0 auto"}}>
                        <h1 id="tile-project-link" style={{color: "#ECF0F7"}}>
                            <b>{props.titleBold}</b>{props.title}
                        </h1>
                    </a>
                </Row>
            
                <Row className="justify-content-center">
                    <p style={{width: "80%",fontSize: 14, fontWeight: 'normal', textAlign: "justify"}}>{props.text}</p>
                </Row>
            </Container>
        </div>
    );
};

export default Tile;