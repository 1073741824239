import './Index.css';

import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import Minter from './util/Minter';
import Timeline from './util/Timeline';

function Helicon() {

    return (
        <div className="geospringer">
            <div className="home-page">
                <div className="page-title">
                    <p id="landing-title-txt"><b>heli</b>con</p>
                </div>
                {/* <Minter></Minter> */}
                <div className="d-flex justify-content-center">
                    <Container fluid style={{padding: 0}}>
                        <div className="d-flex justify-content-center flex-column" id='minter'>
                            <div style={{backdropFilter: "blur(10px)", width: "100%", height: 900, position: "absolute", top:110}}></div>

                            <div className="opaque-wrapper">
                                <b>coming soon</b>
                                <br></br>
                                <p>Q1, 2022</p>
                            </div>
                            
                            <Row style={{width: "50%", margin: "0 auto", marginTop: 175, zIndex: 2}}>
                                <Col>
                                    <div className="d-flex justify-content-center flex-column">
                                        <a className="preview-link" href="/helicon/art">
                                            <h4 className="preview-link-title">sneak peaks</h4>
                                        </a>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="d-flex justify-content-center flex-column">
                                        <a className="preview-link" href="/roadmap">
                                            <h4 className="preview-link-title">roadmap</h4>
                                        </a>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="d-flex justify-content-center flex-column">
                                        <a className="preview-link" href="https://discord.gg/P5WCMxhT">
                                            <h4 className="preview-link-title">join our discord</h4>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default Helicon;