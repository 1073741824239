import './Index.css';
import React from 'react';
import About from './home/About';
import Team from './home/Team';
import Impact from './home/Impact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import landing from './img/landing_hd30.mp4';

function Home() {
    return (
        <div className="home">
            <div className="home-page">
                <div className="page-title">
                    <p id="landing-title-txt"><b>meta</b>physics</p>
                    <p style={{marginTop: -20, fontSize: 26}}><b>art</b>haus</p>
                </div>
                <video  
                    id="video"
                    preload='true' 
                    autoPlay 
                    muted 
                    loop
                    playsinline
                >
                    <source src={landing} type="video/mp4"></source>
                </video>
                <div className="page-title" style={{top: "65%"}}>
                    <p style={{fontSize: "1em", margin: "0 auto", width: "50%"}}>
                        <i>
                            …a boutique NFT art house that emphasises calculated aesthetics, community, sustainability and innovation.
                        </i>
                    </p>
                    <p style={{fontSize: "1em", margin: "0 auto", width: "50%"}}>
                        <a id="about-metaphysics" style={{width: "100%", height: 50, paddingTop: 13}} href="#about-metaphysics">
                            <FontAwesomeIcon icon={faChevronDown} style={{fontSize: 30}} />
                        </a>
                    </p>
                </div>    
            </div>

            <div id="about-main-container">
                <About></About>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <Team></Team>
            </div>

            <div id="team-main-container">
                <Impact></Impact>
            </div>

        </div>
    );
}

export default Home;