import { Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import gif1 from '../img/springers/sp3.gif';
import gif2 from '../img/springers/sp5.gif';
import gif3 from '../img/springers/sp1.gif';
import gif4 from '../img/springers/sp4.gif';
import gif5 from '../img/springers/sp8.gif';
import gif6 from '../img/springers/sp6.gif';
import gif7 from '../img/springers/sp7.gif';
import gif8 from '../img/springers/sp2.gif';
import gif9 from '../img/springers/sp9.gif';
import gif10 from '../img/springers/sp10.gif';

const Preview = () => {
    return (
        <div className="home-page">
            
            <a 
                href="/helicon" 
                style={{zIndex:3, 
                        position: "absolute", 
                        top:97, left: "3vw", 
                        width: 100, height: 30, 
                        padding:5}} >
                            <p style={{color: "#fff", fontSize: 16}}>
                                <FontAwesomeIcon icon={faChevronLeft} /> back
                            </p>
            </a>
            
            <div className="page-title">
                <h1><b>art</b> preview</h1>
            </div>
            <div id="carousel-container">
                <Carousel 
                    fade={true}
                    nextLabel=''
                    prevLabel=''
                >
                    <Carousel.Item>
                        <img className="d-block w-100" src={gif1} alt=''/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={gif2} alt=''/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={gif3} alt=''/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={gif4}v/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={gif5} alt=''/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={gif6} alt=''/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={gif7} alt=''/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={gif8} alt=''/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={gif9} alt=''/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={gif10} alt=''/>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    );
};

export default Preview;
