import './Index.css'
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import { Container, Navbar, Nav } from 'react-bootstrap';
import { connectWallet, getCurrentWalletConnected } from "./util/interact.js";

function Navigation() {
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");

    useEffect(async () => {
        const { address, status } = await getCurrentWalletConnected();
    
        setWallet(address);
        setStatus(status);
    
        addWalletListener();
    }, []);
    
    function addWalletListener() {
        if (window.ethereum) {
        window.ethereum.on("accountsChanged", (accounts) => {
            if (accounts.length > 0) {
            setWallet(accounts[0]);
            setStatus("");
            } else {
            setWallet("");
            setStatus("🦊 Connect to Metamask using the top right button.");
            }
        });
        } else {
        setStatus(
            <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
            </a>
            </p>
        );
        }
    }
    
    const connectWalletPressed = async () => {
        const walletResponse = await connectWallet();
        setStatus(walletResponse.status);
        setWallet(walletResponse.address);
    };
    return (
        <div className="navigation">
            <Navbar className="justify-content-start" bg="light" variant="light" collapseOnSelect expand="md">
                <Container style={{marginLeft: 0, maxWidth: 1000}}>
                    <Navbar.Brand href="/">
                        <p id="branding"><b>meta</b>physics</p>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"><FontAwesomeIcon icon={faBars} style={{color: "#fff"}}/></Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto" id="nav-links" style={{ width: "100%"}}>
                            <Nav.Link href="/roadmap" style={{width: "100%"}}>roadmap</Nav.Link>
                            <Nav.Link href="/projects" style={{width: "100%"}}>projects</Nav.Link>

                            {/* SOCIALS */}
                            <Nav.Link href="https://discord.gg/P5WCMxhT" style={{width: "100%"}}><FontAwesomeIcon icon={faDiscord}/></Nav.Link>
                            <Nav.Link href="https://twitter.com/metaphysics_ah" style={{width: "100%"}}><FontAwesomeIcon icon={faTwitter}/></Nav.Link>
                            
                            {/* WALLET CONNECTIVITY */}
                            <div style={{textAlign: "center", width:"100%", marginRight: 20}}>
                            <Navbar.Text style={{display: "inline-block"}}>
                                <button id="walletButton" onClick={connectWalletPressed}>
                                {walletAddress.length > 0 ? (
                                    "Connected: " +
                                    String(walletAddress).substring(0, 6) +
                                    "..." +
                                    String(walletAddress).substring(38)
                                ) : (
                                    <span>connect wallet</span>
                                )}
                                </button>
                            </Navbar.Text>
                            </div>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default Navigation;