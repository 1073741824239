import './timeline.css';
import mobius from '../img/roadmap/geoart.png'

const Timeline = () => {

    return (
        <div className="timeline">
            {/* TIMELINE ELEMENT 1 */}
            <div className="timeline__component">
                <div className="timeline__date timeline__date--right"><b>Q1</b>, 2022</div>
            </div>

            <div className="timeline__middle">
                <div className="timeline__point"></div>
            </div>

            <div className="timeline__component timeline__component--bg">
                <h2 className="timeline__title">
                    <p className="timeline__title--main">
                        <b className="timeline__title--start">Stage 1:</b> helicon
                    </p>
                </h2>
                <hr/>
                <p className="timeline__paragraph">
                    <ul>
                        <li>Kickstart the MPAH trilogy</li>
                        <li>3,333 Helicon NFTs</li>
                        <li>First 500 to join dicord recieve whitelist status</li>
                        <li>Tokenomics released</li>
                    </ul>
                </p>
            </div>

            {/* TIMELINE ELEMENT 2 */}
            <div className="timeline__component timeline__component--bg">
                <h2 className="timeline__title">
                    <p className="timeline__title--main">
                        <b className="timeline__title--start">Stage 2:</b> perpetual
                    </p>
                </h2>
                <hr/>
                <p className="timeline__paragraph">
                    <ul>
                        <li>Free AR enabled airdrop to all <b>helicon</b> holders</li>
                        <li>Second launch in the MPAH trilogy</li>
                        <li>3,333 Perpetual NFTs</li>
                        <li>Helicon holders automatically whitelisted for pre-mint allocation</li>
                        <li><b>Occulus Rift</b> competition announced</li>
                        <li>Metaphysics Arthaus NFT capture app announced</li>
                        <li>Negotiations with other NFT projects to enable MPAH app functionality</li>
                    </ul>
                </p>
            </div>

            <div className="timeline__middle">
                <div className="timeline__point"></div>
                {/* <div className="timeline__point timeline__point--bottom"></div> */}
            </div>

            <div className="timeline__component">
                <div className="timeline__date"><b>Q2</b>, 2022</div>
            </div>
            
            {/* TIMELINE ELEMENT 3 */}
            <div className="timeline__component">
                <div className="timeline__date timeline__date--right"><b>Q3</b>, 2022</div>
            </div>

            <div className="timeline__middle">
                <div className="timeline__point"></div>
                
            </div>

            <div className="timeline__component timeline__component--bg">
            <h2 className="timeline__title">
                    <p className="timeline__title--main">
                        <b className="timeline__title--start">Stage 3:</b> minimal
                    </p>
                </h2>
                <hr/>
                <p className="timeline__paragraph">
                    <ul>
                        <li>Final launch in the MPAH trilogy</li>
                        <li>3,333 Minimal NFTs</li>
                        <li><b>Occulus Rift</b> competition winners announced</li>
                        <li>Fourth project by Metaphysics Arthaus announced</li>
                    </ul>
                </p>
            </div>

        
            {/* TIMELINE ELEMENT 4 */}
            <div className="timeline__component timeline__component--bg">
                <h2 className="timeline__title">
                    <p className="timeline__title--main">
                        <b className="timeline__title--start">Stage 4:</b> apogee
                    </p>
                </h2>
                <hr/>
                <p className="timeline__paragraph">
                    <ul>
                        <li>MPAH flagship project launch using one NFT from each prior stage to generate a fourth 
                            fully interactive, 3D and AR-ready NFT with rarity derived from the rarity of the 
                            progenitors</li>
                        <li>Metaphysics Arthaus Capture App launched and compatibility roadmap outlined</li>
                    </ul>
                </p>
            </div>

            <div className="timeline__middle">
                <div className="timeline__point"></div>
            </div>

            <div className="timeline__component">
                <div className="timeline__date timeline__date"><b>Q4</b>, 2022</div>
            </div>

            {/* TIMELINE ELEMENT **END** */}
            <div className="timeline__component">
                <div className="timeline__date--right">2023 - 2024</div>
            </div>
            <div className="timeline__middle timeline__middle--end">
                <div className="timeline__point"></div>
                {/* <div className="timeline__point timeline__point--bottom"></div> */}
            </div>
            <div className="timeline__component timeline__component--bg timeline__component--end">
                <h2 className="timeline__title">
                    <p className="timeline__title--main">
                        <b className="timeline__title--start">Stage 5:</b> horizon 
                    </p>
                </h2>
                <hr/>
                <p className="timeline__paragraph">
                    <ul>
                        <li>AR-based exhibitions held concurrently around the world and in various major cities.</li>
                        <li>First steps toward full Virtual Reality integration</li>
                        <li>Expansion of MPAH NFT Capture App compatibility with major projects and increased end-user utility.</li>
                    </ul>
                </p>
            </div>
            
            {/* FOOTER */}
            <div className="timeline__footer">
                <h1 className="timeline__footer--text">strap in for a reality-bending journey into the future</h1>
            </div>

            <div className="ground__plane--wrapper">
            <div className="ground__plane">
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </div>
            </div>
        </div>
    );
};

export default Timeline;