import { Container, Col, Row } from 'react-bootstrap';
import Tile from '../util/Tile';

import geospringer from '../img/geospringer.gif'
import geoart from '../img/geoart.png'
import rubriks from '../img/rubriks.png'
import minimal from '../img/minimal.png'

const Projects = () => {
    return (
        <div className="home-page">
            <div className="page-title">
                <h1><b>upcoming</b> projects</h1>
            </div>
            <br></br>
            <Container fluid style={{paddingTop: "140px"}}>
                <Row className="justify-content-center">
                    <Col style={{width: "100%", marginBottom: 70}}>
                        <Tile 
                            image={geospringer} 
                            titleBold="helicon" title="" 
                            href="/helicon"
                            text="The first installment of the metaphysics trilogy. A series of 3,333 NFTs that 
                            celebrate complex mathematical geometry through unique algorithmically generated 
                            3D GIF art. Helicon is the first step in our journey towards an Arthaus 
                            Metaverse. Owning a Helicon will unlock exclusive benefits and opportunities 
                            including whitelisting and discounted mint price in our next NFT project."></Tile>
                    </Col>                            
                    <Col style={{width: "100%", marginBottom: 70}}>
                        <Tile 
                            image={geoart} 
                            titleBold="perpertual" 
                            title="" 
                            href="#"
                            text="Coming soon..."></Tile>
                    </Col>
                    <Col style={{width: "100%", marginBottom: 70}}>
                        <Tile 
                            image={minimal} 
                            titleBold="minimal" 
                            title="" 
                            href="#"
                            text="Coming soon..."></Tile>
                    </Col>
                </Row>
                {/* <Row className="justify-content-center">
                    <Col style={{width: "100%", marginBottom: 70}}>
                        <Tile image={rubriks} titleBold="coming" title=" soon" href="#"></Tile>
                    </Col>
                </Row> */}
            </Container>
        </div>
    );
};

export default Projects;