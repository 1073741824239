import '../Index.css'
import { Container, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'
import teamImage1 from '../img/team/team1.png';
import teamImage2 from '../img/team/team2.png';
import teamImage3 from '../img/team/team3.png';

const Team = () => {
    return (
        <div className="justify-content-center" id="about">
            <Container>
                <Row className="justify-content-center" id="about-title">
                    <h1><b>the</b> team</h1>
                </Row>
                <br></br>
                <Row className="justify-content-center" id="about-content">
                    <Row className="justify-content-center" xs={6} md={4}>
                        <Col style={{textAlign:"center"}}>
                            {/* <img id="team-image" src={teamImage1}></img> */}
                            <br></br>
                            <br></br>
                            <h2>AL. G. Rhythm</h2>
                            <Row><a href="https://twitter.com/ToadhallToady" style={{color: "#fff", margin: "0 auto"}}><FontAwesomeIcon icon={faTwitter}/></a></Row>
                            <br></br>
                            <p id="team-text-inner">
                                Explorer and Experience Designer with a background in engineering. Passionate 
                                about NFTs, computational design, AI, and Augmented Reality. 2022 will be the 
                                year of L2s. 
                            </p>
                            
                        </Col>
                        <Col style={{textAlign:"center"}}>
                            {/* <img id="team-image" src={teamImage2}></img> */}
                            <br></br>
                            <br></br>
                            <h2>0xBigPicture</h2>
                            <Row><a href="https://twitter.com/0xBigPicture" style={{color: "#fff", margin: "0 auto"}}><FontAwesomeIcon icon={faTwitter}/></a></Row>
                            <br></br>
                            <p id="team-text-inner">
                                A full-stack AI/ML researcher and Quant developer with a 
                                background in computational design. Currently obsessed with blockchain edge-tech 
                                applications and mountain biking.
                            </p>
                            
                        </Col>
                        <Col style={{textAlign:"center"}}>
                            {/* <img id="team-image" src={teamImage3}></img> */}
                            <br></br>
                            <br></br>
                            <h2>NY Platypus</h2>
                            <Row><a href="https://twitter.com/NY_Platapus" style={{color: "#fff", margin: "0 auto"}}><FontAwesomeIcon icon={faTwitter}/></a></Row>
                            <br></br>
                            <p id="team-text-inner">
                                Experience working in a large Digital Asset fund. Early crypto and NFT adopter. Excited 
                                by exponential technology and the convergence thesis. Strong believer in Web3.0 and 
                                the Metaverse. Recently acquired a Spikeball kit.
                            </p>
                            
                        </Col>
                    </Row>
                </Row>
            </Container>
        </div>
    );
};

export default Team;