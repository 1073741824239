import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Home,
  Roadmap,
  Projects,
  Helicon,
  Preview,
} from "./components";

setTimeout(ReactDOM.render(
  <Router>
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/roadmap" element={<Roadmap />} />
      <Route path="/projects" element={<Projects />} />

      <Route path="/helicon" element={<Helicon />} />
      <Route path="/helicon/art" element={<Preview />} />
    </Routes>
  </Router>,

  document.getElementById("root")
), 1500);

reportWebVitals();