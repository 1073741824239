import { Container, Row } from 'react-bootstrap';

const About = () => {
    return (
        <div className="justify-content-center" id="about">
            <Container id="geospringer-about-wrapper">
                <Row className="justify-content-center" id="about-title">
                    <h1><b>about</b> us</h1>
                </Row>
                <Row className="justify-content-center" id="about-content">
                    <Row xs={6} md={4}>
                        <div  id="about-text">
                            <p id="about-text-inner">Metaphysics Art Haus is a forward-looking creative studio that is committed 
                            to pushing the boundaries of art and technology in the metaverse. 
                            <br></br>
                            <br></br>
                            At Metaphysics Art Haus we believe 
                            that two technological disciplines are beginning to converge: Augmented Reality and NFT technology. In 
                            the future, NFT art will be given shape and substance by AR devices creating new opportunities for 
                            aspiring artists. At MPAH our art is responsive to this future state. We design 3D generative art 
                            and store it on the Ethereum blockchain to create sculptural pieces with interactable properties. 
                            <br></br>
                            <br></br>
                            Our plan is to start by releasing an NFT trilogy that celebrates complex mathematical geometries through 
                            unique algorithmically generated 3D GIF art. These NFTs represent the physical expression of mathematical 
                            concepts they are enriching, beautiful objects that resonate within this paradigm and the metaverse. 
                            'Helicon' is our inaugural NFT project and the first of its kind. This series will redefine the limits of 
                            contemporary art. 
                            <br></br>
                            <br></br>
                            Holders of a trilogy set will be able to deposit their NFTs into a smart contract to 
                            receive a fourth NFT, which will be generated by reference to the unique traits of the former NFTs. This 
                            ensures rarity and uniqueness by creating a possible 200m combinations. Additionally, holders of the fourth 
                            NFT unlock god tier utility, giving them early access to our interactive NFT capture app. 
                            <br></br>
                            <br></br>
                            The interactive NFT capture app is truly revolutionary. At the end of Q4 2022 MPAH will release an interactive global app with 
                            NFTs of different rarity scattered across the globe. Holders of the fourth NFT will be given early access to 
                            the app and will be able to find and capture these NFTs using AR compatible devices with no mint price. When 
                            the time comes, MPAH will partner with various other NFT projects to negotiate the inclusion of their NFTs on 
                            our AR Captcha platform. This is the equivalent to Willy Wonka's golden ticket; you do not want to miss out.
                            </p>
                        </div>
                    </Row>
                </Row>
            </Container>
        </div>
    );
};

export default About;